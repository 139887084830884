import { validDangeroutStr, validateAge } from './validateResumeField.js';
//validFns为自定义的校验函数，如validFns = { "validateMobile"：validateMobile, "validateEmail": validateEmail}
export const rules = (validFns) => {
    validFns.validateMobile = validFns.validateMobile || null;
    validFns.validateMobileScore = validFns.validateMobileScore || null;
    validFns.validateEmail = validFns.validateEmail || null;
    validFns.validateAge = validateAge || null;
    validFns.validateRealName = validFns.validateRealName || null;
    validFns.validateRealNameText = validFns.validateRealNameText || null;
    validFns.validateYearOfExperience = validFns.validateYearOfExperience || null;
    validFns.validateWorkDateRange = validFns.validateWorkDateRange || null;
    validFns.validateEducationMajor = validFns.validateEducationMajor || null;
    validFns.validateEducationDateRange = validFns.validateEducationDateRange || null;
    validFns.validateProjectDateRange = validFns.validateProjectDateRange || null;
    return {
        realName: [
            { required: true, message: '姓名不能为空', trigger: ['change', 'blur'] },
            { min: 1, max: 50, message: '不能超过 50 个字符', trigger: ['change', 'blur'] },
            {  validator: validFns.validateRealNameText, trigger: 'blur' },
            {  validator: validFns.validateRealName, trigger: 'blur' }
        ],
        customerId: [
            { required: true, message: '输入企业名称快捷选取', trigger: ['change', 'blur'] }
        ],
        customerDepartmentId: [
            { required: true, message: '输入部门名称快捷选取', trigger: ['change', 'blur'] }
        ],
        projectId: [
            // { required: true, message: '输入项目名称快捷选取', trigger: ['change', 'blur'] }
        ],
        mobile: [
            {  validator: validFns.validateMobileScore, trigger: 'blur' },
            {  validator: validFns.validateMobile, trigger: 'blur' }
        ],
        email: [
            { validator: validFns.validateEmail, trigger: 'change' }
        ],
        city: [
            { required: true, min: 1, message: '城市不能为空', type: 'number', trigger: 'change' }
        ],
        age: [
            { validator: validFns.validateAge, trigger: ['blur', 'change'] }
        ],
        wechat: [
            { validator: validFns.valideteWechat, trigger: 'change' },
        ],
        yearOfExperience: [
            { validator: validFns.validateYearOfExperience, trigger: 'change' }
        ],
        extraInfo: [
            { min: 1, max: 800, message: '不能超过 800 个字符', trigger: ['change', 'blur'] },
            {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
        ],
        selfEvaluation: [
            { min: 1, max: 4000, message: '不能超过 4000 个字符', trigger: ['change', 'blur'] },
            {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
        ],
        experienceRule: {
            company: [
                { required: true, message: '公司名不能为空', trigger: 'blur' },
                { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            title: [
                { required: true, message: '职位不能为空', trigger: 'blur' },
                { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            description: [
                { min: 20, message: '不能少于 20 个字符', trigger: ['blur'] },
                {  max: 8000, message: '不能超过 8000 个字符', trigger: ['change', 'blur'] },
            ],
            dateRange: [
                {  validator: validFns.validateWorkDateRange, trigger: ['change', 'blur'] },
            ],
        },
        educationRule: {
            school: [
                { required: true, message: '学校名不能为空', trigger: ['change', 'blur'] },
                { min: 2, message: '不能少于 2 个字符', trigger: ['change', 'blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            degree: { required: true, message: '学历不能为空', type: 'number', trigger: 'change' },
            major: [
                { validator: validFns.validateEducationMajor, trigger: ['change', 'blur'] },
                { min: 2, message: '不能少于 2 个字符', trigger: ['change', 'blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            dateRange: [
                { validator: validFns.validateEducationDateRange, trigger: ['change', 'blur'] },
            ]
        },
        projectRule: {
            projectName: [
                { required: true, message: '项目名称不能为空', trigger: ['change', 'blur'] },
                { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            projectRole: [
                { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                { max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            description: [
                { min: 20, message: '不能少于 20 个字符', trigger: ['blur'] },
                { max: 8000, message: '不能超过 8000 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            responsibility: [
                { min: 20, message: '不能少于 20 个字符', trigger: ['blur'] },
                { max: 2000, message: '不能超过 2000 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            dateRange: [
                { validator: validFns.validateProjectDateRange, trigger: ['change', 'blur'] },
            ]
        },
        intention: {
            title: [
                { required: true, message: '期望职位不能为空', trigger: ['change', 'blur'] },
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            locationArr: [
                { required: true, message: '期望城市不能为空', type: 'array', trigger: 'change' }
            ]
        }
    }
}

export const jobMultiCreateRules = (validFns) => {
    validFns.validateMobile = validFns.validateMobile || null;
    validFns.validateEmail = validFns.validateEmail || null;
    validFns.validateRealName = validFns.validateRealName || null;
    validFns.validateYearOfExperience = validFns.validateYearOfExperience || null;
    return {
        customerId: [
            { required: true, message: '输入企业名称快捷选取', trigger: ['change', 'blur'] }
        ],
        customerDepartmentId: [
            { required: true, message: '输入部门名称快捷选取', trigger: ['change', 'blur'] }
        ],
        projectId: [
            { required: true, message: '输入项目名称快捷选取', trigger: ['change', 'blur'] }
        ],
    }
}

export const personalRules = (validFns) => {
    validFns.validateMobile = validFns.validateMobile || null;
    validFns.validateEmail = validFns.validateEmail || null;
    validFns.validateRealName = validFns.validateRealName || null;
    validFns.validateYearOfExperience = validFns.validateYearOfExperience || null;
    return {
        realName: [
            { required: validFns.validateRealName.require, message: '姓名不能为空', trigger: ['change', 'blur'] },
            { min: 1, max: 50, message: '不能超过 50 个字符', trigger: ['change', 'blur'] },
            {  validator: validFns.validateRealName.fn, trigger: 'change' }
        ],
        customerId: [
        ],
        mobile: [
            {  validator: validFns.validateMobile.fn, trigger: 'change' },
            { required: validFns.validateMobile.require, message: '请输入手机号或邮箱', trigger: ['change', 'blur'] },
        ],
        email: [
            {  validator: validFns.validateEmail.fn, trigger: 'change' },
            { required: validFns.validateEmail.require, message: '请输入手机号或邮箱', trigger: ['change', 'blur'] },
        ],
        city: [
        ],
        yearOfExperience: [
            {  validator: validFns.validateYearOfExperience, trigger: ['change', 'blur'] }
        ],
        extraInfo: [
            { min: 1, max: 800, message: '不能超过 800 个字符', trigger: ['change', 'blur'] },
            {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
        ],
        selfEvaluation: [
            { min: 1, max: 4000, message: '不能超过 4000 个字符', trigger: ['change', 'blur'] },
            {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
        ],
        experienceRule: {
            company: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            title: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            description: [
                { min: 1, max: 8000, message: '不能超过 8000 个字符', trigger: ['change', 'blur'] },
            ]
        },
        educationRule: {
            school: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            degree: [],
            major: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ]
        },
        projectRule: {
            projectName: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            projectRole: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            description: [
                { min: 1, max: 8000, message: '不能超过 8000 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            responsibility: [
                { min: 1, max: 2000, message: '不能超过 2000 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ]
        },
        intention: {
            title: [
                { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['change', 'blur'] },
                {  validator: validDangeroutStr, trigger: ['change', 'blur'] }
            ],
            locationArr: [
            ]
        }
    }
}