import {Message} from 'element-ui';
import resumeSingleService from '#/js/service/resumeSingleService.js';
let emailReg = /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)|(^(1[3-9])\d{9}$)/;
let mobileReg = /^(1[1-9])\d{9}$/;
let regexMobileExternal = /^(\+|00)\d{8,15}$/;
let regexMobileInternal = /^(1[1-9])\d{9}$/;
let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;

export const validateRealName = (rule, value, callback, isNeedResumeRepeat = false, data = {}, fn) => {
    if(!isNeedResumeRepeat){
        callback();
        return;
    }
    let isRightMobile = mobileReg.test(data.mobile) || regexMobileExternal.test(data.mobile) || regexMobileInternal.test(data.mobile);
    if(isRightMobile){
        resumeSingleService.resumeValidateRepeat(data).then((r) =>{
            fn(r);
        }).catch((r) => {
            fn(r);
        });
    }
}

export const validateRealNameScore = (rule, value, callback, fn) => {
    resumeSingleService.validRealNameScore({
        realName: value
    }).then(res => {
        callback();
        return;
    }).catch(err => {
        callback(new Error("请输入真实有效的姓名"));
        return;
    })
}

export const validateMobileScore = (rule, value, callback, candidateId, firmId, cb, markInvalidCb) => {
    if($('#input-mobile').find('.mobile-error').length>0){
        $('#input-mobile').find('.mobile-error').remove();
    }
    if(value===null){
        callback(new Error('请输入手机号'));
        return;
    }
    let arr = value.match(mobileReg) || value.match(regexMobileExternal) || value.match(regexMobileInternal);
    if(value===''){
        callback(new Error('请输入手机号'));
    }else if(arr === null){
        callback(new Error('请输入正确的手机号码'));
    }else{
        if (candidateId) {
            resumeSingleService.validMobileScore({
                resumeId: candidateId,
                mobile: value
            }).then(res => {
                callback();
                return;
            }).catch(err => {
                if (err.succeeded) {
                    callback();
                    return;
                } else {
                    if (err.data > 0) {
                        cb && cb(1, err.data);
                    } else if (err.data == 0) {
                        cb && cb(2);
                    } else if (err.data == -1) {
                        cb && cb(3);
                    }

                    markInvalidCb();

                    callback(new Error("请输入真实有效的手机号"));
                    return;
                }
            })
        } else {
            resumeSingleService.validMobileScore({
                mobile: value
            }).then(res => {
                callback();
                return;
            }).catch(err => {
                // markInvalidCb();
                // callback(new Error("请输入真实有效的手机号"));
                // return;
            })
        }
    }
}

export const validateMobile = (rule, value, callback, isNeedResumeRepeat = false, data = {}, fn) => {
    if($('#input-mobile').find('.mobile-error').length>0){
        $('#input-mobile').find('.mobile-error').remove();
    }
    if(value===null){
        callback(new Error('请输入手机号'));
        return;
    }
    let arr = value.match(mobileReg) || value.match(regexMobileExternal) || value.match(regexMobileInternal);
    if(value===''){
        callback(new Error('请输入手机号'));
    }else if(arr === null){
        callback(new Error('请输入正确的手机号码'));
    }else{
        if(isNeedResumeRepeat && data && data.realName) {
            resumeSingleService.resumeValidateRepeat(data).then((r) =>{
                fn && fn({
                    ...r,
                    succeeded: true,
                });
            }).catch((r) => {
                fn && fn(r);
            });
            callback();
        } else {
            callback();
        }
    }
}
export const validateEmail = (rule, value, callback, isNeedResumeRepeat = false, data = {}, fn) => {
    if(value===null){
        callback(new Error('请输入邮箱'));
        return;
    }
    let reg = emailReg.test(value);
    if($('#input-email').find('.to-detail').length>0){
        $('#input-email').find('.to-detail').remove();
    }
    if(!reg && value!==''){
        callback(new Error('请输入正确的电子邮箱'));
    }else if(value.length>50){
        callback(new Error('邮箱最长支持50个字符'));
    }else {
        if(isNeedResumeRepeat && value!=='' && !!data.realName && !!data.mobile) {
            resumeSingleService.resumeValidateRepeat(data).then((r) =>{
                fn(r);
            }).catch((r) => {
                fn(r);
            });
            callback();
        } else {
            callback();
        }
    }
}
export const validateAge = (rule, value, callback) => {
    let reg = /^[0-9]\d*$/;
    if(value == '' || value == undefined) {
        callback();
    } else if(!reg.test(value)) {
        callback(new Error("年龄必须为整数"));
    } else if(value == '0'){
        callback(new Error("年龄不能为0"))
    } else if(parseInt(value) > 99 || parseInt(value) < 0) {
        callback(new Error("年龄的范围为1~99"))
    } else {
        callback();
    }
}
export const validDangeroutStr = (rule, value, callback) => {
    let reg = dangerousStringReg.test(value);
    if(reg){
        callback(new Error('您输入的内容包含非法字符'));
    }else{
        callback();
    }
}
export const validEmails = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请输入正确的邮箱'));
    }
    let arr = value.split(';');
    for(let i=0;i<arr.length;i++){
        let reg =emailReg.test(arr[i]);
        if (!reg){
            Message.error(arr[i]+'不是有效的邮箱')
            callback(new Error('请输入正确的邮箱'));
        }
    }
    callback();
}
export const validateTel = (rule, value, callback) => {
    let arr = value.match(/([0-9]{3,4}-)?[0-9]{7,8}/);
    if (arr !== null || value == '') {
        callback();
    } else {
        callback(new Error('请输入正确的号码'));
    }
}

export const validatePassword = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入密码'));
    } else {
        if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{7,20}$/.test(value)) {
            callback(new Error('密码为7~20个字符，字母+数字组合'));
        } else{
            callback();
        }
    }
};
